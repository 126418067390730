<template>
    <v-container fluid>
        <template>
            
            <v-row>
                <v-col cols="6" class="pb-1 pt-0">
                    <v-breadcrumbs :items="itemsBreadcrumbs" class="pl-0 pt-0 pb-1">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                            :to="item.href"
                            :disabled="item.disabled"
                            >
                            {{ item.text.toUpperCase() }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-col>
                <v-col cols="6" class="pb-1 pt-0 h-100">
                    <office-login></office-login>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="7" class="pt-1">
                    <span class="headline font-weight-medium">Kárdex</span>
                    <br>
                    <storehouse-breadcrumb></storehouse-breadcrumb>
                </v-col>
                <v-col cols="5">
                    <span class="title font-weight-medium text-uppercase float-right">
                        ({{ inventory.name }})
                    </span>
                </v-col>
            </v-row>

            <!--<v-row>
                <v-col cols="1">
                    <span>DESDE: </span>
                </v-col>
                <v-col cols="2" class="pt-1">
                    <v-menu
                        v-model="menuInit"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="date_init"
                                readonly
                                v-on="on"
                                solo
                                hide-details                         
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="date_init" 
                            @input="menuInit = false" 
                            no-title
                            locale="es">
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="1">
                    <span>HASTA: </span>
                </v-col>
                <v-col cols="2" class="pt-1">
                    <v-menu
                        v-model="menuEnd"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="date_end"
                                readonly
                                v-on="on"
                                solo
                                hide-details                         
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="date_end" 
                            @input="menuEnd = false" 
                            no-title
                            locale="es">
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="2" class="pt-2">
                    <v-btn color="green"
                        dark
                        @click="getAllKardex">
                        Listar
                    </v-btn>
                </v-col>
            </v-row>-->

            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-card-title class="pt-0 pb-0">
                            <span class="ml-1 pt-3 pb-3 subtitle-1 font-weight-bold w-title-table">Todos los movimientos</span>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text class="pt-0 pl-0 pr-0">
                                <v-simple-table dense height="63vh" class="table-kardex">
                                <thead class="table-kardex-head">
                                    <tr>
                                        <th class="text-left text-uppercase" rowspan="2">Fecha</th>
                                        <th class="text-left text-uppercase column-hora" rowspan="2">Hora</th>
                                        <th class="text-left text-uppercase column-tipo-movimiento" rowspan="2">Tip. Movimiento</th>
                                        <th class="text-left text-uppercase column-movimiento" rowspan="2">Movimiento</th>
                                        <th class="text-left text-uppercase column-tipo-documento" rowspan="2">Tipo Doc.</th>
                                        <th class="text-left text-uppercase" rowspan="2">Serie</th>
                                        <th class="text-left text-uppercase" rowspan="2">Número</th>
                                        <th class="text-center text-uppercase" colspan="3">Entrada</th>
                                        <th class="text-center text-uppercase" colspan="3">Salida</th>
                                        <th class="text-center text-uppercase" colspan="3">Existencias</th>
                                    </tr>
                                    <tr>
                                        <th class="text-center text-uppercase">Cant.</th>
                                        <th class="text-center text-uppercase">Costo</th>
                                        <th class="text-center text-uppercase">Total</th>

                                        <th class="text-center text-uppercase">Cant.</th>
                                        <th class="text-center text-uppercase">Costo</th>
                                        <th class="text-center text-uppercase">Total</th>

                                        <th class="text-center text-uppercase">Cant.</th>
                                        <th class="text-center text-uppercase">Costo</th>
                                        <th class="text-center text-uppercase">Total</th>
                                    </tr>
                                </thead>

                                <tbody class="table-kardex-body">
                                    <tr v-for="item in kardex" :key="item.id">
                                        <td>{{ item.operation_date  | moment("DD/MM/YYYY") }}</td>
                                        <td>{{ item.operation_date  | moment("hh:mm a") }}</td>
                                        <td>{{ item.type }}</td>
                                        <td>{{ item.sub_type }}</td>
                                        <td>{{ item.type_document }}</td>
                                        <td>{{ item.serie }}</td>
                                        <td>{{ item.correlative }}</td>
                                        <td>
                                            <span class="label-quantity-entry" v-if="item.type == 'INGRESO'">{{ item.quantity }} ({{ item.quantity/1000 }} KG)</span>
                                        </td>
                                        <td>
                                            <span v-if="item.type == 'INGRESO'">{{ item.cost| currency('S/') }}</span>
                                        </td>
                                        <td>
                                            <span v-if="item.type == 'INGRESO'">{{ item.cost * item.quantity | currency('S/') }}</span>
                                        </td>
                                        <td>
                                            <span v-if="item.sub_type == 'MERMA'" class="label-quantity-merma">{{ item.quantity }} ({{ item.quantity/1000 }} KG)</span>
                                            <span v-else-if="item.sub_type == 'CONSUMO INTERNO'" class="label-quantity-interno">{{ item.quantity }} ({{ item.quantity/1000 }} KG)</span>
                                            <span v-else-if="item.sub_type == 'VENTA'" class="label-quantity-output">{{ item.quantity }} ({{ item.quantity/1000 }} KG)</span>
                                        </td>
                                        <td>
                                            <span v-if="item.type == 'SALIDA'">{{ item.cost | currency('S/') }}</span>
                                        </td>
                                        <td>
                                            <span v-if="item.type == 'SALIDA'">{{ item.total | currency('S/') }}</span>
                                        </td>
                                        <td>
                                            <span class="label-quantity-stock">{{ item.stock }} ({{ item.stock/1000 }} KG)</span>
                                        </td>
                                        <td>
                                            <span>{{ item.cost | currency('S/') }}</span>
                                        </td>
                                        <td>
                                            <span>{{ item.total | currency('S/') }}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

        </template>
    </v-container>
</template>

<script>
import router from "@/router/index";
import {mapState, mapActions} from 'vuex'
import OfficeLogin from '../../../components/auth/OfficeLogin';
import StorehouseBreadcrumb from '../../../components/storehouses/StorehouseBreadcrumb'
import ProductionApi from '../../../apis/Production';

export default {
    name: 'kardexInsumos',
    components: {
        StorehouseBreadcrumb,
        OfficeLogin
    },
    data() {
        return {
            kardex: [],
            itemsBreadcrumbs: [
                {
                    text: 'Logística',
                    disabled: true,
                    href: '',
                },
                {
                    text: 'Almacenes',
                    disabled: false,
                    href: '/app/logistica/almacenes',
                }
            ],
            headers: [
                { text: 'FECHA', value: 'operation_date' },
                { text: 'HORA', value: 'operation_date' },
                { text: 'MOVIMIENTO', value: 'sub_type' },
                { text: 'TIP. MOVIMIENTO', value: 'type' },
                { text: 'TIPO. DOC', value: 'type_document' },
                { text: 'NUM. DOC', value: 'serie' },
                { text: '', value: 'actions' },
            ],
            menuInit: false,
            menuEnd: false,
            date_init: this.$moment().subtract(7, 'days').format('YYYY-MM-DD'),
            date_end: this.$moment().format('YYYY-MM-DD')
        }
    },  
    computed: {
        ...mapState('inventories', [
            'inventory',
            'office_name',
            'sede_name',
            'company_name'
        ])
    },
    methods: {
        loadData() {
            let supply_id = this.inventory.id
            
            console.log(supply_id);
            ProductionApi.getKardexBySupply(supply_id).then((value) => {
                this.kardex = value.data;
                console.log(this.kardex);
            });
        }
    },
    mounted() {
        this.loadData()
    },
    created() {
        //let supply = router.params.id;
        //console.log(supply);
        this.loadData();
    }
}
</script>

<style scoped>
.table-kardex{
    overflow-y: auto;
}
.table-kardex-head{
    background-color: #f2f2f2 !important;
}

.label-quantity-entry{
    background-color: #43a047;
    color: #ffffff;
    display: block;
    width: 120px;
    text-align: center;
    border-radius: 4px;
}

.label-quantity-output{
    background-color: #e53935;
    color: #ffffff;
    display: block;
    width: 120px;
    text-align: center;
    border-radius: 4px;
}

.label-quantity-stock{
    background-color: #3F51B5;
    color: #ffffff;
    display: block;
    width: 120px;
    text-align: center;
    border-radius: 4px;
}

.label-quantity-merma{
    background-color: #FFC107;
    color: #ffffff;
    display: block;
    width: 120;
    text-align: center;
    border-radius: 4px;
}

.label-quantity-interno{
    background-color: #9C27B0;
    color: #ffffff;
    display: block;
    width: 120;
    text-align: center;
    border-radius: 4px;
}

.column-hora{
    width: 70px !important;
    min-width: 70px !important;
}

.column-movimiento{
    max-width: 200px !important;
    min-width: 100px !important;
}

.table-kardex-body td{
    height: 40px !important;
}

.table-kardex td, .table-kardex th{
    padding: 0 2px !important;
}
</style>