import { render, staticRenderFns } from "./kardexInsumos.vue?vue&type=template&id=3b648964&scoped=true&"
import script from "./kardexInsumos.vue?vue&type=script&lang=js&"
export * from "./kardexInsumos.vue?vue&type=script&lang=js&"
import style0 from "./kardexInsumos.vue?vue&type=style&index=0&id=3b648964&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b648964",
  null
  
)

export default component.exports